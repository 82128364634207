import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ALLOWED_MODE, COLORS } from 'applicationConstants';
import { useErrorContent } from 'hooks/useErrorContent';
import { copyTextToClipboard } from 'utils/copyToClipboard';
import { applicationFlowMode } from 'utils/applicationModeUtils';
import { ApplicationFlowTemplate } from 'components/ApplicationPage/ApplicationFlowTemplate';
import BackButton from '../BackButton';
import { MoreInfoModal } from './MoreInfo';
import { CcLink } from '../CcLink';
import { CcTypography } from '../CcTypography';
import { useModalControl } from '../../hooks/useModalControll';
import getText from '../../utils/getText';
import { resetLoader } from '../../store/app';
import { ApplicationPage } from '../ApplicationPage/ApplicationPage';
import { ApplicationTitle } from '../ApplicationPage/ApplicationTitle';
import { ApplicationContent } from '../ApplicationPage/ApplicationContent';
import { ApplicationFooter } from '../ApplicationPage/ApplicationFooter';
import { useSendErrorNotification } from '../../hooks/useSendErrorNotification';
import Attention96 from '../Icons/Obsolete/Attention96';

const useStyles = makeStyles(() => createStyles({
  button: {
    textTransform: 'none',
  },
}));

type Props = {
  error?: object;
  errorInfo?: object;
  resetError: () => void;
  useGoBackText?: boolean;
  additionalErrorContent?: object;
};

export const ErrorBoundaryComponent = ({
  error, errorInfo, resetError, useGoBackText, additionalErrorContent,
}: Props) => {
  const [openMoreInfo, infoController] = useModalControl();
  const dispatch = useDispatch();
  const networkMode = useSelector((state) => state.auth.network?.mode);
  const loaderStatus = useSelector((state) => state.app.loader);

  const styles = useStyles();
  const contentData = useErrorContent({
    error,
    errorInfo,
    additionalContent: additionalErrorContent,
  });

  const strContent = JSON.stringify(contentData, null, ' ');
  useSendErrorNotification({ content: contentData });

  useEffect(() => {
    // Reset loader in case if some requests stuck and UI is blocked
    if (loaderStatus) {
      dispatch(resetLoader());
    }
  }, []);
  return (
    <ApplicationFlowTemplate>
      <ApplicationPage
        ladyStyle="lady2RedCircleWithOuterCircle"
      >
        <ApplicationTitle
          title={getText('global.errorPage.title')}
        />
        <ApplicationContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Attention96 />
            <Box
              marginTop="43px"
              marginBottom="42px"
            >
              <CcTypography
                customColor={COLORS.lightGray}
                variant="body1"
                align="center"
              >
                {getText('global.errorPage.p1')}
              </CcTypography>
              <br />
              <CcTypography
                customColor={COLORS.lightGray}
                variant="body1"
                align="center"
              >
                {getText('global.errorPage.p2')}
              </CcTypography>
            </Box>
            {
              applicationFlowMode === 'general' && (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={resetError}
                  aria-label={`${getText('global.errorPage.closeApplication')} on Error page`}
                >
                  {getText('global.errorPage.closeApplication')}
                </Button>
              )
            }
          </Box>
        </ApplicationContent>
        {
          networkMode && networkMode !== ALLOWED_MODE.prod && (
            <ApplicationFooter>
              <Box marginTop="30px" marginLeft="10px">
                <CcLink
                  component={Button}
                  className={styles.button}
                  text={getText('global.errorPage.moreInfo')}
                  color={COLORS.lightGray}
                  onClick={infoController.open}
                />
                <CcLink
                  component={Button}
                  className={styles.button}
                  text={getText('global.errorPage.copy')}
                  color={COLORS.lightGray}
                  onClick={() => {
                    copyTextToClipboard(strContent);
                  }}
                />
              </Box>
            </ApplicationFooter>
          )
        }
      </ApplicationPage>
      {
            openMoreInfo && (
            <MoreInfoModal
              contentItems={contentData}
              onClose={infoController.close}
            />
            )
        }
      { applicationFlowMode === 'general' && (
        <BackButton
          goBack={resetError}
          fixed
        >
          {
            useGoBackText ? getText('sidebar.back') : getText('sidebar.backToHome')
          }
        </BackButton>
      )}
    </ApplicationFlowTemplate>
  );
};
