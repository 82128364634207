/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function Attention96({
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={96}
      sizeY={96}
      customViewBox="0 0 88 88"
      color="none"
      position={position}
    >
      <circle cx="44" cy="44" r="44" fill="#FFE2E1" />
      <g clipPath="url(#clip0)">
        <path
          d="M62.1285 56.4365L45.3215 28.1878C45.1763 27.9762 44.9798 27.8027 44.7492 27.6828C44.5187 27.5628 44.2613 27.5 43.9998 27.5C43.7384 27.5 43.481 27.5628 43.2504 27.6828C43.0199 27.8027 42.8233 27.9762 42.6782 28.1878L25.8712 56.4365C25.7372 56.6624 25.6666 56.9187 25.6665 57.1795C25.6664 57.4404 25.7369 57.6966 25.8708 57.9226C26.0047 58.1486 26.1974 58.3363 26.4294 58.4669C26.6615 58.5975 26.9248 58.6664 27.1928 58.6667H60.8068C61.0749 58.6664 61.3382 58.5975 61.5702 58.4669C61.8023 58.3363 61.9949 58.1486 62.1289 57.9226C62.2628 57.6966 62.3332 57.4404 62.3332 57.1795C62.3331 56.9187 62.2625 56.6624 62.1285 56.4365ZM45.8425 37.8518L45.1198 47.6645H42.8799L42.1984 37.8518H45.8425ZM43.9998 54.2063C43.5565 54.2063 43.1232 54.0784 42.7546 53.8388C42.386 53.5991 42.0987 53.2585 41.929 52.8599C41.7594 52.4614 41.715 52.0228 41.8015 51.5997C41.8879 51.1766 42.1014 50.788 42.4149 50.483C42.7284 50.1779 43.1278 49.9702 43.5626 49.8861C43.9973 49.8019 44.448 49.8451 44.8576 50.0102C45.2672 50.1753 45.6172 50.4548 45.8635 50.8135C46.1098 51.1722 46.2413 51.5939 46.2413 52.0253C46.2413 52.6037 46.0051 53.1585 45.5848 53.5675C45.1644 53.9766 44.5943 54.2063 43.9998 54.2063Z"
          fill="#D22E2C"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="36.6667" height="36.6667" fill="white" transform="translate(25.6665 25.667)" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
}
